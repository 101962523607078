<template>
  <b-card>
    <validation-observer ref="UserMasterValidation">
      <b-form>
        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              @click="$router.go(-1)"
            >
              <feather-icon icon="SkipBackIcon" /> Back
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="First Name"
                rules="required"
              >
                <b-form-input
                  v-model="form.user_first_name"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  placeholder="First Name"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Last Name"
                rules="required"
              >
                <b-form-input
                  v-model="form.user_last_name"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  placeholder="Last Name"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  v-model="form.user_email"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  placeholder="Email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Mobile"
                :rules="{ regex: /^[2-9]\d{2}[2-9]\d{2}\d{4}$/ }"
              >
                <b-form-input
                  v-model="form.user_mobile"
                  :state="errors.length > 0 ? false : null"
                  type="number"
                  placeholder="Mobile"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider #default="{ errors }" name="City" rules="">
                <b-form-input
                  v-model="form.user_city"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  placeholder="City"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="User Name"
                rules="required"
              >
                <b-form-input
                  v-model="form.user_username"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  placeholder="User Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Password"
                :rules="user_id?'':'required'"
              >
                <b-form-input
                  v-model="form.user_password"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  placeholder="Password"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Role Name"
                rules="required"
              >
              <v-select
                  :reduce="(AllRole) => AllRole.role_id"
                  label="role_name"
                  placeholder="Role Name"
                  v-model="form.user_role_id"
                  :options="$store.getters['user/getRoles']"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="text-center">
            <b-button variant="primary" type="submit" @click.prevent="saveUser">
              Save
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BCard,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required, email } from "@validations";
import UserServices from "@/apiServices/UserServices";
import { BFormSelect } from "bootstrap-vue";

export default {
  name: "IngredientForm",
  directives: {
    Ripple,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardText,
    BFormTextarea,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
  },
  data() {
    return {
      user_id: null,
      form: {
        user_id: "",
        user_first_name: "",
        user_last_name: "",
        user_email: "",
        user_mobile: "",
        user_city: "",
        user_username: "",
        user_password: "",
        user_role_id: "",
        user_is_active: true,
      },
      required,
      email,
      selected: null,
    };
  },

  methods: {
    saveUser() {
      this.$refs.UserMasterValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.form.user_id = this.user_id;
            const roleMaster = await UserServices.saveUser(this.form);

            if (roleMaster.data.status) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: roleMaster.data.message || "Role Added Successfull",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
              this.$router.replace("/users/user-list");
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: roleMaster.data.message || "Cannot Added Role",
                  icon: "EditIcon",
                  variant: "failure",
                },
              });
            }
          } catch (error) {
            console.log("Error in saveUser ", error);
            console.log({ error });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message || "Error in saveUser",
                icon: "EditIcon",
                variant: "failure",
              },
            });
          }
        }
      });
    },
    async getSingleUser(user_id) {
      
      try {
        const response = await UserServices.getSingleUser(user_id);
        let singleUser = response.data.data;
        if (response.data.status) {
          this.form = {
            user_first_name: singleUser.user_first_name,
            user_last_name: singleUser.user_last_name,
            user_email: singleUser.user_email,
            user_mobile: singleUser.user_mobile,
            user_city: singleUser.user_city,
            user_username: singleUser.user_username,
            user_role_id: singleUser.user_role_id
              ? parseInt(singleUser.user_role_id)
              : null,
            user_is_active: singleUser.user_is_active,
          };
        }
      } catch (err) {
        console.log("Error in getting  ", err);
      }
    },
  },
  beforeMount() {
    this.$store.dispatch("masters/setCountry");
    this.$store.dispatch("masters/setLanguage");
    this.$store.dispatch("user/setRole");
    if (this.$route.params.user_id && !isNaN(this.$route.params.user_id)) {
      this.user_id = this.$route.params.user_id;
      this.getSingleUser(this.user_id);
    }
  },
};
</script>

<style></style>
